import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { GamesList, ProductPage } from '@homeplay/components';
import { SiteContext, selectVisoGames } from '@homeplay/app';
import classes from './VisoGames.module.scss';
import visoGamesLogo from '../../../../shared/assets/images/viso-games-logo.png';

//TODO: temporary solution to exclude games from touch unit
const excludedVisoGamesOnTouchUnit = [
  'visharkbounty',
  'vibillyonrush',
  'vibellsnwhistles',
  'vidragonstash',
  'breakdbank',
  '5dragons',
  'moneygame',
];

const VisoGames = () => {
  const { isDesktop, isTouchUnit } = useContext(SiteContext);
  const visoGames = useSelector(selectVisoGames);

  const updatedVisoGames = useMemo(() => {
    if (!isTouchUnit) return visoGames;

    return (
      visoGames?.filter(
        (game) => !excludedVisoGamesOnTouchUnit.includes(game.slug)
      ) ?? []
    );
  }, [visoGames, isTouchUnit]);

  const getGameLink = (loadParams) => {
    return `https://${process.env.REACT_APP_ENV === 'prod' ? '' : 'vx-'}${loadParams.slug.replaceAll('_', '-')}.${process.env.REACT_APP_VISOTRONIX_BASE_URL}?token=${loadParams.player_token}&device=${isDesktop ? 'desktop' : 'mobile'}&mode=home_play&exit=${window.location.origin}/exit`;
  };

  return (
    <ProductPage getGameLink={getGameLink} customClass={classes.VisoGames}>
      <div className="container">
        <img
          className={classes.Logo}
          src={visoGamesLogo}
          alt="VisoGames"
          width="228"
          height="100"
        />
        {updatedVisoGames && <GamesList games={updatedVisoGames} />}
        <p className={classes.Info}>
          We are working hard to bring your favourite Viso Games here.
        </p>
        <p className={classes.Info + ' ' + classes.Light}>
          Watch this space as more games are coming soon!
        </p>
      </div>
    </ProductPage>
  );
};

export default VisoGames;
